import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAFXmx-hfClSVgnjOs-of0Mxz74L_GUDe0",
  authDomain: "portfolio-21255.firebaseapp.com",
  projectId: "portfolio-21255",
  storageBucket: "portfolio-21255.appspot.com",
  messagingSenderId: "640680969584",
  appId: "YOUR_FIREBASE_APP_ID",
  measurementId: "G-LLYKVN4V5E"
};

// init firebase
const app = initializeApp(firebaseConfig);

// init services
const firestoreDatabase = getFirestore(app);
const firebaseStorage = getStorage(app);
const analytics = getAnalytics(app);

export { firestoreDatabase, firebaseStorage, analytics };